<template>
  <div>
    <!--Title Bar-->
    <title-bar v-if="applicationSection === 1" :title-value="'Applications'"></title-bar>

    <!-- Title Bar For Section List-->
    <v-card v-else flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-ballot-check</v-icon>
        <v-toolbar-title class="pl-2">Applications</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar v-if="applicationSection !== 2" flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card max-width="400px">
          <v-card-text>
            <v-container fluid>
              <!--Clear Filter Button-->
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>

                <!--WLS ID-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_wlsID"
                    label="WLS ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--First Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_nameFirst"
                    label="Applicant First Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Last Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_nameLast"
                    label="Applicant Last Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Applying Child First Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="applyingChildFirst"
                    label="Applying Child First Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Applying Child Last Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="applyingChildLast"
                    label="Applying Child Last Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>

                <!--Status-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.applicationStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <!--Submit Button-->
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <!--Application Pop Up Dialog-->
      <application-entry :application-section="1" />
    </v-toolbar>

    <!--Pagination  -->
    <pagination
      :show="applicationsStore.applications.data && applicationsStore.applications.data.length > 0"
      :showTotal="true"
      :currentPage="applicationsStore.applications.current_page"
      :lastPage="applicationsStore.applications.last_page"
      :total="applicationsStore.applications.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-basic
        :show="
          applicationsStore.applications.data && applicationsStore.applications.data.length > 0
        "
        :list-data="applicationsStore.applications.data"
        :title="'Applications'"
        :section="17"
        @open-entry="openEntry($event)"
        @open-delete-dialog="openDeleteDialog($event)"
      />
    </div>

    <!-- Delete Dialog Box -->
    <v-dialog v-model="deleteDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Delete Application?</span>
          <v-spacer />
          <v-btn outlined rounded text @click.native="closeDeleteDialog()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="12">
                {{ deleteMessage }} will be deleted from the system!
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            rounded
            color="error"
            @click.native="removeApplication(recordID)"
            >Delete</v-btn
          >
        </v-card-actions>
        <v-spacer />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import ListBasic from "@/components/ListBasic";
import ApplicationEntry from "@/components/ApplicationEntry";

const { mapFields } = createHelpers({
  getterType: "applicationsStore/getFilterField",
  mutationType: "applicationsStore/updateFilterField"
});

export default {
  name: "Applications",
  components: {
    TitleBar,
    Pagination,
    ListBasic,
    ApplicationEntry
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      applicationsStore: state => state.applicationsStore,
      progressStore: state => state.progressStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "page",
      "section",

      "fk_householdID",
      "d_wlsID",
      "d_nameFirst",
      "d_nameLast",
      "applyingChildFirst",
      "applyingChildLast",
      "f_status",
      "f_viewPrivateRecords"
    ])
  },
  props: {
    /* All List = 1, Clients = 2 */
    applicationSection: {
      type: Number,
      required: false
    }
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      filterMenu: false,
      deleteMessage: '',
      recordID: '',
      deleteDialog: false
    };
  },
  methods: {
    async initialize() {
      if (this.applicationSection === 1) {
        this.$store.dispatch("progressStore/set", true);
      }

      await this.changeSectionCheck();

      /* Client section */
      if (this.applicationSection === 2) {
        /* Grab client details based on client UUID passed in route parameter */
        // await this.$store.dispatch('clientsStore/read', this.$route.params.clientUUID);
        const client = this.$store.getters["clientsStore/getClient"];
        this.fk_householdID = client.fk_householdID;
      } else {
      /* All list */
        this.fk_householdID = "";
      }

      const user = this.$store.getters["usersStore/getAuthUser"];
      this.f_viewPrivateRecords = user.f_viewPrivateRecords;

      /* Pull applications list */
      const filters = this.$store.getters["applicationsStore/getFilters"];
      await this.$store.dispatch("applicationsStore/fetch", filters);

      this.$store.dispatch("progressStore/set", false);
    },

    async loadValueLists() {
      /* Set type = 2 to filter for CLIENT specific document types */
      const data = {
        f_type: 2
      };
      await this.$store.dispatch("documentTypesStore/valueList", data);
      /* Pull counties list for pulldowns */
      await this.$store.dispatch("countiesStore/valueList");
      /* Set value list ID's used in Application pop up */
      const data2 = {
        id: [2, 3, 4, 5, 6, 7, 8, 12, 18, 21]
      };
      await this.$store.dispatch("valueListsStore/items", data2);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.d_wlsID = "";
      this.d_nameFirst = "";
      this.d_nameLast = "";
      this.applyingChildFirst = "";
      this.applyingChildLast = "";
      this.f_status = "";
      this.page = 1;
    },

    async changeSectionCheck() {
      await this.loadValueLists();
      if (this.applicationSection !== this.section) {
        this.clearFilterColumns();
        this.$store.dispatch("applicationsStore/clearApplications");
      }
      this.section = this.applicationSection;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (
        this.d_wlsID ||
        this.d_nameFirst ||
        this.d_nameLast ||
        this.applyingChildFirst ||
        this.applyingChildLast ||
        this.f_status ||
        this.f_status === 0
      ) {
        return true;
      }
      return false;
    },

    async openEntry(id) {
      await this.loadValueLists();
      await this.$store.dispatch("applicationsStore/entry", id);
    },

    openDeleteDialog(data) {
      this.deleteMessage = data.message;
      this.recordID = data.id;
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
      this.recordID = '';
      this.deleteMessage = '';
    },

    async removeApplication(id) {
      this.closeDeleteDialog();
      const data = {
          id: id
        };
      const response = await this.$store.dispatch("applicationsStore/delete", data);
      let message;
      if (response && response.status === 200) {
        message = {
          color: "success",
          text: "Application has been deleted."
        };
      } else {
        message = {
          color: "error",
          text: "There is an issue with deleting the application at this time."
        };
      }

      this.$store.commit("messagesStore/setMessage", message);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
